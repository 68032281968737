.button{
  border-radius: 1.5rem;
  white-space: nowrap;
}

#back-button{
  margin-top: 1rem;
  height: 2rem;
  font-size: 1.5rem;
  white-space: nowrap;
}

.icon-button, .link-button{
  border-radius: 0.5rem;
  background-color: #444;
  padding: 0.5rem 0.75rem;
  display: flex;
  flex-direction: row;
  width:fit-content;
  max-height: 3.25rem;
}
  
.icon-button-text{
  padding: 0.25rem 0 0 0.5rem;
}

.icon-button img{
  width: 2.5rem;
  height: 2.5rem;
}

  