.link-list-li.blog{
    background-color:#333;
    border-radius: 0.5rem;
    padding: 1rem 1rem 1rem 1rem;
    margin-top: 1rem;
}

.link-list-li.blog:hover{
    background-color:rgb(82, 82, 82);
    transition: 0.25s;
    transform:scale(1.015);
}

.header.blog{
    padding: 0;
    margin: 0;
    /* color:#bcf; */
}

.tagline.blog{
    margin-top: 0rem;
}

.tagline-grid{
    display: grid;
    grid-template-columns: 0fr 1fr;
    gap: 1rem;
}

/*make images square*/
img.blog{
    width: 15rem;
    border-radius: 0.5rem;
}

.root.blog {
    margin-top: 2rem;
    list-style-type: none;
    padding: 0rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.blog-list.inline{
    display: grid;
    grid-template-columns: 4fr 4rem;
    gap: 2rem;
}

.blog-list-date{
    margin-bottom: 0rem;
}

.viewcount{
    margin-top: 0.25rem;
    text-align: right;
}

.blog-img-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

@media screen and (max-width: 485px){
    .root.blog{
        display: block;
    }

    .tagline-grid{
        display: block;
    }

    img.blog{
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 695px) and (min-width: 486px){
    .root.blog{
        display: block;
    }

    .tagline-grid{
        display: grid;
    }
}

@media screen and (max-width: 990px) and (min-width: 696px){
    img.blog{
        margin-bottom: 1rem;
    }

    .tagline-grid{
        display: block;
    }
}