#home{
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
}

#selfie{
  border-radius: 0.5em;
  margin: 1rem 0 1rem 0;
  width: 20rem;
}

#tagline{
  margin: 0;
  padding: 0em 0 1rem 0;
}

.contacts{
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 4fr;
  gap: 1em;
}

#home .icon-button {
  font-size: 1.25rem;
}

#home .link-button{
  font-size: 1.25rem;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

#home .icon-button img{
  width: 1.75rem;
  height: 1.75rem;
}

.home-content h3{
  margin-top: 2rem;
}

@media screen and (min-width: 1050px) {
  #home{
    grid-template-columns: 1fr 1fr 1fr;
  }
  .home-content{
    grid-column: 2 / 4;
  }
}

@media screen and (max-width: 1049px) { 
  #selfie{
    display: block;
    margin: 1rem auto 1.23rem auto;
  }
  #tagline{
    text-align: center;
  }
}

@media screen and (max-width: 470px) { 
  .contacts{
    margin-top: 0.7rem;
    display: flex;
    flex-direction: column;
    float: left;
  }
}