.projects ul{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  gap: 1.5rem;
  padding: 0 1em 0 1em;
}

.project-image{
  margin: 0 auto;
  width: 21rem;
}

.project-image img{
  width: 21rem;
  padding-bottom: 0rem;
}

.projects li{ /*cards*/
  background-color:#333;
  border-radius: 0.5em;
  padding: 1em;
  transition: 0.1s;
  display:flex;
  height: 26rem;
  flex-direction:column;
}

.projects .icon-button {
  font-size: 1.25rem;
}

.projects .link-button{
  font-size: 1.25rem;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.image-text{
  position:relative;
  padding: 0.5rem;
  margin-bottom: -2.7rem;
  color: #000;
  background-color: #bcfa;
}

.link-list-li.git .tagline{
  margin: 0.25rem;
}

.header.git {
  margin-top: 0;
  margin-bottom: 1.5rem;
  /* color:#bcf; */
}

.projects li:hover{
  background-color:rgb(82, 82, 82);
  transition: 0.25s;
  transform:scale(1.015);
}

/* .projects h3{
  margin-top: 0.5em;
} */

.projects .icon-button img{
  width: 1.75rem;
  height: 1.75rem;
}

.icon-button-text{
  padding: 0.2em 0 0 0.5em;
}

@media screen and (max-width: 1325px){
  .projects ul{
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 920px){
  .projects ul{
    grid-template-columns: 1fr;
  }
}

.link-list-links.git{
  margin-top: auto;
  display:flex;
  flex-direction:row;
  justify-content: center;
  gap: 0.5rem;
}

@media screen and (max-width: 520px) {
  .project-image{
    width:18rem;
  }

  .project-image img{
    width:18rem;
  }

  .link-list-links.git{
    flex-direction: column;
  }

  .projects .link-button{
    margin: 0 auto;
  }

  .projects .icon-button{
    margin: 0 auto;
  }

  .projects li{
    height: 29rem;
  }
}

@media screen and (max-width: 432px){
  .project-image{
    width:15rem;
  }

  .project-image img{
    width:15rem;
  }

  .projects li{
    height: 27rem;
  }
}



/* @media screen and (max-width: 400px){
  .projects ul{
    grid-template-columns: 1fr 1fr;
  }
} */