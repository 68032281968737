body{
  color: #eee;
}

h1 {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

h2 {
  margin-top: 2rem;
  margin-bottom: 0rem;
}

h3 {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
}

p {
  line-height: 1.5rem;
}

a {
  text-decoration: none;
}

a:link {
  color: #cad;
}

a:visited {
  color: #cad;
}

a:hover {
  color: #bcf;
  text-decoration:wavy;
}

a:active {
  color: #bcf;
}

ul{
  list-style: none;
}

li{
  padding: 0 0 0.5rem 0;
}

#hero{
  position: fixed;
  top: 0rem;
  z-index: 8;
  width: 100vw;
  background-color: #333;
  display: grid;
  grid-template-columns: 12rem 1fr 2fr 2fr;
  gap: 3rem;
}

.blue-text{
  color:#bcf;
}

.nav-bar{
  font-size: 1.2em;
  max-width: 500px;
  padding: 0.2rem 0rem 0rem 0rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  text-align: center;
  gap: 0rem;
}

.nav-link{
  padding: 0 1rem 0 1rem;
}

.burger {
  display: none;
}

.hero.h1{
  padding: 0rem 2rem 0.6rem 2rem;
  font-size: 2em;
}

.content{
  margin: 0 auto;
  max-width: 1200px;
  padding: 6rem 2rem 3rem 2rem;
}

#elliot-mb{
  color: #eee;
}

/* .body{
  min-height: 100vh;
} */

.footer{
  margin-top: 5rem;
}

/* @media screen and (max-width: 566px) { 

  #hero{
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0rem;
  }
} */

@media screen and (max-width: 645px) {

  /* #hero{
    display:block;
    padding: 0.001rem;
  } */

  .nav-bar {
    display:none;
  }

  .burger {
    display:block;
  }

  .desc{
    margin: 0 0rem 0 0rem;
  }
}

