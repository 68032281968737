
#readme{
  color: #cad;
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0rem 0 0rem 0;
  font-size: 1em;
}

#readme:hover{
  color: #bcf;
}

#markdown-box{
  background-color:#444;
  padding: 1rem 1.5rem 1rem 1.5rem;
  margin-bottom: 1rem;
  border-radius: 0.5em;
}

#markdown-box h1{
  padding: 0 0 0 0;
  
}

#markdown-box a {
  text-decoration: underline;
} 

.git-project-info{
  margin-bottom: 1rem;
}

.git-project-inner-image {
  padding: 1rem 0 1rem 0;
  width: 30rem;
  
}

code{
  white-space:normal;
}

.shown-false{
  width:fit-content;
  height:fit-content;
}

.shown-true{
  padding-bottom: 0.5em;
}

.git-project-inner-image{
  max-width: min(calc(950px), calc((100vw - 7rem)));
}

#git-project .link-button{
  margin: 0 0;
  font-size: 1rem;
  height: 2rem;
}

@media screen and (min-width: 950px) {
  #git-project{
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
  }
  .git-project-inner-image{
    max-width: min(calc(536px), calc((100vw - 12rem) / 2));
  }
  #markdown-box{
    max-width: min(calc(536px), calc((100vw - 12rem) / 2));
  }
}
