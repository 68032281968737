.inline{
    display: flex;
    flex-direction: row;
}

.inline.project-page-title{
    gap: 1rem;
}

.inline.blog-header{
    gap: 1rem;
}
